<template>
  <div class="wrapper">
    <el-carousel class="slideBox" height="100%" :interval="5000" arrow="never">
      <!-- <el-carousel-item> -->
      <div class="slideBox1">
        <div class="content animate__animated animate__lightSpeedInRight">
          <div class="title1">联系我们</div>
          <div class="title2">
            <p style="text-align: center; z-index: 1">
              <span style="letter-spacing: 15px; z-index: 1"
                ><span style="color: rgb(255, 255, 255); font-family: 'Arial Black'; z-index: 1"><span style="font-size: 14px; z-index: 1">CO</span></span
                ><span style="color: rgb(0, 255, 192); z-index: 1"
                  ><span style="font-family: 'Arial Black'; z-index: 1"><span style="font-size: 14px; z-index: 1">N</span></span></span
                ><span style="color: rgb(255, 255, 255); font-family: 'Arial Black'; z-index: 1"><span style="font-size: 14px; z-index: 1">TACT </span></span
                ><span style="color: rgb(0, 255, 192); z-index: 1"
                  ><span style="font-family: 'Arial Black'; z-index: 1"><span style="font-size: 14px; z-index: 1">U</span></span></span
                ><span style="color: rgb(255, 255, 255); font-family: 'Arial Black'; z-index: 1"><span style="font-size: 14px; z-index: 1">S</span></span></span
              >
            </p>
          </div>
        </div>
      </div>
      <!-- </el-carousel-item> -->
    </el-carousel>
    <div class="wrapper-content">
      <div class="row_content">
        <div class="content" style="width: 900px">
          <div style="text-align: center; margin: 90px auto 10px auto">
            <div style="font-size: 30px; font-weight: bold; line-height: 50px; background: url(/images/about/point1.png) repeat-x bottom; background-size: auto 33%">我们期待与您的联系</div>
          </div>
          <div style="margin: 30px auto; text-align: left; line-height: 2; color: #666666; font-size: 14px">
            沧穹科技产业定位为音频室内定位技术提供商，专注于上游核心技术研发，努力与产业生态上下游合作伙伴一起，推动音频高精度定位技术进入实际场景应用，让其成为室内定位强有力的技术路径选择，并成为北斗系统在室内环境中的重要补充。诚挚欢迎芯片制造商、模组制造商、终端软硬件集成商、室内定位服务商以及场景需求方联系洽谈！
          </div>
          <div class="content_col">
            <div class="col_4 info">
              <div class="info_img"><img src="/images/icons/ad.png" /></div>
              <div class="info_msg">浙江省湖州市德清县<br>云岫南路611号2幢武汉大学技术转移中心7楼</div>
            </div>
            <div class="col_4 info">
              <div class="info_img"><img src="/images/icons/email.png" /></div>
              <div class="info_msg">wenjing.gong@zlnavi.com</div>
            </div>
            <div class="col_4 info">
              <div class="info_img"><img src="/images/icons/qq.png" /></div>
              <div class="info_msg">(QQ)7432993</div>
            </div>
            <div class="col_4 info">
              <div class="info_img"><img src="/images/icons/wx.png" /></div>
              <div class="info_msg">(微信)13928417858</div>
            </div>
          </div>
        </div>
        <div style="height: 80px"></div>
      </div>
      <div class="row_content" style="background-color: #f3f3f3; padding: 70px 0">
        <div class="content" style="background-color: #ffffff; padding: 40px">
          <div style="font-size: 30px; font-weight: bold; line-height: 40px">您可以通过下方地图找到我们</div>
          <div style="width: 50px; height: 9px; margin: 15px 0 30px 0; background-color: #00ffc0"></div>
          <div style="height: 500px">
            <el-amap vid="amapDemo" zoom="18" :center="center" plugin="ToolBar">
              <el-amap-marker :position="center"></el-amap-marker>
            </el-amap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  data() {
    return {
      center: [119.978707, 30.520017],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "about.scss";
</style>
